<template>
  <v-card id="promotion-list">

    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          @click="sync()"
        >
          <span>Синхронизировать</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="menuVersions"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <!-- name -->
      <template #[`item.version`]="{item}">
        {{ item.version }}
      </template>

      <template #[`item.synchronizationTime`]="{item}">
        {{ toDateTimeString(item.synchronizationTime) }}
      </template>

      <template #[`item.isActual`]="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="18"
              :color="resolveMenuStatusVariantAndIcon(item.isActual).variant"
            >
              {{ resolveMenuStatusVariantAndIcon(item.isActual).icon }}
            </v-icon>
          </template>
        </v-tooltip>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'menu-version-menu-category-list', params: { menuVersionId: item.id } }"
              >
                <v-icon size="18">
                  {{ icons.mdiEye }}
                </v-icon>
              </v-btn>
            </template>
            <span>Просмотреть</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="item.isActual"
                @click="activateMenu(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiCheck }}
                </v-icon>
              </v-btn>
            </template>
            <span>Активировать</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'menu-version-translations', params: { id: item.id } }"
              >
                <v-icon size="18">
                  {{ icons.mdiFlag }}
                </v-icon>
              </v-btn>
            </template>
            <span>Конфигурация языков</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import { toDateTimeString } from '@/helpers'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiCheck,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiFlag,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiPencilOutline,
  mdiEye,
} from '@mdi/js'

export default {
  name: 'MenuVersionList',
  props: ['restaurantId'],
  data: () => ({
    toDateTimeString,
    icons: {
      mdiCheckCircleOutline,
      mdiTrendingUp,
      mdiPlus,
      mdiAlertCircleOutline,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiCheck,
      mdiFlag,
      mdiEyeOutline,
      mdiEye,
    },
  }),
  computed: {
    ...mapFormHandlers('menuVersion', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.restaurantId',
      'filters.sortDesc',
    ]),
    ...mapState('menuVersion', {
      menuVersions: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Версия', value: 'version' },
        { text: 'Дата', value: 'synchronizationTime' },
        { text: 'Активное', value: 'isActual' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async mounted() {
    this.filters.restaurantId = this.restaurantId
    await this.fetchMenuVersions()
  },
  methods: {
    async sync() {
      await this.syncMenu({
        restaurantId: this.restaurantId,
      })
    },
    ...mapActions('menuVersion', {
      fetchMenuVersions: 'fetchList',
      syncMenu: 'sync',
      activate: 'updateVisibility',
    }),
    resolveMenuStatusVariantAndIcon(status) {
      if (status) return { variant: 'success', icon: mdiCheckCircleOutline }
      return { variant: 'error', icon: mdiAlertCircleOutline }
    },
    async activateMenu(id) {
      await this.activate({
        menuVersionId: id,
        restaurantId: this.restaurantId,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
