<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
      @change="changed"
    >
      <v-tab v-for="(item, index) in restaurantDropDown" :key="index">
        <div :v-html="item.logo"></div>
        <span>{{item.name}}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in restaurantDropDown" :key="index">
          <menu-version-list :restaurant-id="item.id"></menu-version-list>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import MenuVersionList from './menu-version-list'
import { mapFormHandlers } from '@/helpers/forms'
export default {
  name: 'MenuVersion',
  components: {MenuVersionList},
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapState('restaurant', {
      restaurantDropDown: 'dropdownList',
    }),
    ...mapFormHandlers('menuVersion', ['filters.restaurantId']),
  },
  methods: {
    ...mapActions('restaurant', ['fetchDropDown']),
    changed(val){
      this.filters.restaurantId = this.restaurantDropDown[val].id
    }
  },
  async created(){
    await this.fetchDropDown();
  }
}
</script>
